import classNames from 'classnames';
import _get from 'lodash/get';
import moment from 'moment';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import useDarkMode from '../../../../hooks/useDarkMode';
import { getTokenInactivationSummary, inactivateToken } from '../../../../services/token/token-service';
import { Token, TokenInactivationSummaryList } from '../../../../type/token';
import Avatar from '../../../Avatar';
import Button, { ButtonGroup } from '../../../bootstrap/Button';
import showNotification from '../../../extras/showNotification';
import { TOKEN_STATUS, USER_ROLES_LOWER, USER_TAGS_BR, getTokenStatusColor, getTokenStatusText } from '../../token/token-activation-form/blocks/constants';
import { TableColumn } from '../table-components/table-column';

import Swal from 'sweetalert2';
import DefaultAvatar from '../../../../assets/img/wanna/wanna3.png';
import { UserContext } from '../../../../contexts/user-context';
import { useBoardingPassByTokenId } from '../../../../hooks/entrada-segura/use-boardingpass-by-token-id';
import { useBuildingData } from '../../../../hooks/entrada-segura/use-building-data';
import { useSpotName } from '../../../../hooks/entrada-segura/use-spot-name';
import { useUserData } from '../../../../hooks/entrada-segura/use-user-data';
import { createBoardingpass } from '../../../../services/boarding-pass/boarding-pass-service';
import * as SlackBot from "../../../../services/slack/handle-notifications-slack";
import { getUserSelfieById } from '../../../../services/user/user-service';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../bootstrap/Modal';
import { CardBody } from '../../../bootstrap/Card';
import Input from '../../../bootstrap/forms/Input';
interface HandleGetTokenSummaryParams {
	tokenId: string;
	userName: string;
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
	buildingName: string | undefined;
	spotName: string | undefined;
}
const handleGetTokenSummary = async ({ tokenId, userName, setRefreshTable, buildingName, spotName }: HandleGetTokenSummaryParams) => {	
	return handleInactivateToken({ tokenId, userName, setRefreshTable, buildingName, spotName });
}

interface HandleInactivateTokenParams {
	tokenId: string;
	userName: string;
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
	buildingName: string | undefined;
	spotName: string | undefined;

}
async function handleInactivateToken({ tokenId, userName, setRefreshTable, buildingName, spotName }: HandleInactivateTokenParams) {
	const childrenTokens = await getTokenInactivationSummary(tokenId);
	const grantTokensCount = childrenTokens
		.filter((token) => token.tokenType === 'grant')
		.length;

	Swal.fire({
		title: 'ATENÇÃO',
		html:
			`<span class"fs-3">Você está <span style="color: #e74c3c !important; font-weight: bold !important;">revogando</span> o acesso de ${userName}. Deseja continuar?</span> ${
			grantTokensCount > 0
				? `<br><br><span style="color: #aaa !important;"> <span style="color: #e74c3c !important;"> Atenção: </span> Todas as pessoas autorizadas por ele(a) também perderão seus acessos.</span>`
				: ''
			}`,
		customClass: {
			container: 'bg-dark',
			htmlContainer: 'text-left',
			confirmButton: 'bg-info',
		},
		showDenyButton: true,
		confirmButtonText: 'Sim',
		allowOutsideClick: false,
		denyButtonText: `Não`,
	}).then(async (result) => {
		if (result.isConfirmed) {
			try {
				await inactivateToken(tokenId);
				await SlackBot.HandleInactivationSlack({ buildingName, inactivatingToken: tokenId, inactivatingUser: userName, spotName, childrenTokens });
				showNotification(`Token inativado com sucesso!`, ``, 'success');
				setRefreshTable(true);
			} catch (e) {
				showNotification(`Erro ao inativar o token. Por favor, tente novamente.`, ``, 'danger');
				console.error('Error inactivating token: ', e);
			}
		}
		else if (result.isDenied) {
			showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
		}
	});
};

async function openAvatarModal(avatar: string) {
	Swal.fire({
		title: 'Selfie do usuário',
		html:
			`<img src="${avatar}" style="display: block; !important; margin-left: auto !important; margin-right: auto !important; max-width: 300px !important; max-height: 500px !important;" />`,
		showDenyButton: false,
		confirmButtonText: 'Ok',
		allowOutsideClick: true,
		backdrop: true
	});
}

interface HandleSendBoardingPassProps {
	tokenId: string;
	loggedUser: number;
	userIssuedForName: string;
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
}

export async function handleSendBoardingPass({ tokenId, loggedUser, setRefreshTable, userIssuedForName }: HandleSendBoardingPassProps) {
	Swal.fire({
		title: 'ATENÇÃO',
		html:
			`<span class"fs-3">Você está criando o Boardingpass para <b>${userIssuedForName}</b> do Token <i>${tokenId}</i>. Deseja continuar?</span>`,
		customClass: {
			container: 'bg-dark',
			htmlContainer: 'text-left',
			confirmButton: 'bg-info',
		},
		showDenyButton: true,
		confirmButtonText: 'Sim',
		allowOutsideClick: false,
		denyButtonText: `Não`,
	}).then(async (result) => {
		if (result.isConfirmed) {
			try {
				await createBoardingpass({
					tokenId,
					createdBy: loggedUser,
				});

				showNotification(`Boardingpass criado com sucesso!`, ``, 'success');
				setRefreshTable(true);
			} catch (error) {
				showNotification(`Ocorreu um erro ao criar o Boardingpass! Tente novamente`, ``, 'error');
				console.error(error);
			}
		}
		else if (result.isDenied) {
			showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
		}
	});
}


function getStatusUser(status: string) {
	switch (status) {
		case TOKEN_STATUS.ACTIVE:
			return false;
		case TOKEN_STATUS.INVITATION_SENT:
			return false;
		case TOKEN_STATUS.INACTIVE:
			return true;
		case TOKEN_STATUS.PENDING:
			return false;
		case TOKEN_STATUS.REJECTED:
			return true;
		case TOKEN_STATUS.EXPIRED:
			return true;
		case TOKEN_STATUS.REVOKED:
			return true;
		default:
			return false;
	}
}

interface TokenRowProps {
	token: Token;
	handleUpcomingEdit: Function;
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
	setShouldOpenEditUserForm: React.Dispatch<React.SetStateAction<boolean>>;
	setUserToEdit: React.Dispatch<React.SetStateAction<number>>;
	setShouldOpenSeeAccess: React.Dispatch<React.SetStateAction<boolean>>;
	setTokenToEdit: (token: Token) => void;
	setShouldOpenEditTokenForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TokenRow: React.FC<TokenRowProps> = ({
	token,
	handleUpcomingEdit,
	setRefreshTable,
	setShouldOpenEditUserForm,
	setShouldOpenSeeAccess,
	setUserToEdit,
	setShouldOpenEditTokenForm,
	setTokenToEdit
}) => {
	const { darkModeStatus } = useDarkMode();
	const { user } = useContext(UserContext);

	const issuedAt = _get(token, 'issuedAt');
	const humanizedIssuedAt = moment.utc(issuedAt).format('DD/MM/YYYY HH:mm');
	const expiresAt = _get(token, 'expiresAt');
	const humanizedExpiresAt = expiresAt ? moment.utc(expiresAt).format('DD/MM/YYYY HH:mm') : null;
	const loggedUser = user.id;
	const loggedUserHighestRole = localStorage.getItem('highest-role')

	const userIssuedForId = _get(token, 'userRequested.id', '')
	const userIssuedForName = _get(token, 'userRequested.name', '')
	const userIssuedByName = _get(token, 'userIssued.name', '')
	const role = _get(token, 'role')
	const tag  = _get(token, 'tag', '')
	const wasIssuedByLoggedUser = user.id === token.userIssued.id;
	
	const userData = useUserData({ userId: token.userRequested.id })
	const alreadyHasBoardingPass = useBoardingPassByTokenId({ tokenId: token.id })
	const canSendBoardingPass = user.isSuperUser === true && userData?.inquiryStatus === "accepted" && !alreadyHasBoardingPass;
	const canSeeDocuments = user.isSuperUser === true || loggedUserHighestRole === USER_ROLES_LOWER.CONTRACT_MANAGER;
	const buildingData = useBuildingData({ buildingId: token.buildingId })
	const spotName = useSpotName({ spotId: token.spotId })

	const [avatar, setAvatar] = useState<string>(DefaultAvatar);

	useEffect(() => {
		getUserSelfieById(userIssuedForId, { setAvatar })
	}, [userIssuedForId]);

	const statusColor = getTokenStatusColor(token.status);

	const userActive = getStatusUser(token.status) ? true : false;

	const handleUserToEdit = () => {
		setShouldOpenEditUserForm(true)
		setUserToEdit(token.userRequested.id)
	}

	const handleSeeAccesses = () => {
		setShouldOpenSeeAccess(true)
		setUserToEdit(token.userRequested.id)
	}

	return (
		<>
			<tr key={token.id}>
				<TableColumn>
					<ButtonGroup>
						<Button
							isDisable={userActive}
							isOutline={!darkModeStatus}
							color='dark'
							isLight={darkModeStatus}
							className={classNames('text-nowrap', {
								'border-light': !darkModeStatus,
							})}
							icon='Block'
							style={{ marginRight: '5px' }}
							onClick={() => handleGetTokenSummary({ tokenId: token.id, userName: userIssuedForName, setRefreshTable, buildingName: buildingData?.name, spotName })}
							title={"Inativar"}
						/>
						<>
							{wasIssuedByLoggedUser && token.status === TOKEN_STATUS.ACTIVE && 
								<Button
									isOutline={!darkModeStatus}
									color='dark'
									isLight={darkModeStatus}
									className={classNames('text-nowrap', {
										'border-light': !darkModeStatus,
									})}
									icon='Edit'
									style={{ marginRight: '5px' }}
									onClick={() => {
										setTokenToEdit(token);
										setShouldOpenEditTokenForm(true);
									}}
									title={"Editar"}
								/>
							}
							{canSeeDocuments &&
								<>
									<Button
										isOutline={!darkModeStatus}
										color='dark'
										isLight={darkModeStatus}
										className={classNames('text-nowrap', {
											'border-light': !darkModeStatus,
										})}
										icon='Eye'
										style={{ marginRight: '5px' }}
										onClick={() => handleUserToEdit()}
										title={"Ver documentos"}
									/>
									<Button
										isOutline={!darkModeStatus}
										color='dark'
										isLight={darkModeStatus}
										className={classNames('text-nowrap', {
											'border-light': !darkModeStatus,
										})}
										icon='Checklist'
										style={{ marginRight: '5px' }}
										onClick={() => handleSeeAccesses()}
										title={"Ver acessos"}
									/>
								</>
							}
						</>
					</ButtonGroup>
				</TableColumn>
				<TableColumn>
					<small
						className={`border border-${statusColor} border-2 text-${statusColor} fw-bold px-2 py-1 rounded-1`}>
						{getTokenStatusText(token.status)}
					</small>
				</TableColumn>
				<TableColumn>
					{userIssuedForId && <Avatar title={"Clique para visualizar a selfie"} style={{ cursor: 'pointer' }} onClick={() => openAvatarModal(avatar)} src={avatar} srcSet={avatar} color={'success'} size={40} />}
					<span className='ms-3 d-flex align-items-center text-nowrap'>
						{`${userIssuedForName}`}
					</span>
				</TableColumn>
				<TableColumn>{userIssuedByName}</TableColumn>
				<TableColumn>{tag ? USER_TAGS_BR[tag] : 'Perfil não encontrado'}</TableColumn>
				<TableColumn>{humanizedIssuedAt}</TableColumn>
				<TableColumn>{humanizedExpiresAt ? humanizedExpiresAt : '-'}</TableColumn>
			</tr>
		</>
	);
};